import { Injectable } from '@angular/core';  
import { Subject } from 'rxjs';  
  
@Injectable({  
  providedIn: 'root'  
})  
export class DataSharingService {  
  
  SharingData = new Subject();  
  constructor() { }  
} 

export class DataSharingUploadFacturaService {  
  
  SharingData = new Subject();  
  constructor() { }  
}

export class DataSharingFacturaService {  
  
  SharingData = new Subject();  
  constructor() { }  
}

export class DataSharingFacturaPrecioService {  
  
  SharingData = new Subject();  
  constructor() { }  
}