import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-app-anular-hospitalizacion',
  templateUrl: './app-anular-hospitalizacion.component.html',
  styleUrls: ['./app-anular-hospitalizacion.component.scss']
})
export class AppAnularHospitalizacionComponent implements OnInit {
  @Input() dataCitaMedicaHabitacion:any;
  @Input() dataHospitalSaveOld:any;
  @Output() clickEvent = new EventEmitter();
  motivo:string="";
  userdata: any;

  constructor(private notificationServices: NotificationServices,
    private srvListaCitas: ElementComponentService, 
    public localdata: localStorageServices,
    private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
  }

  cancelarHospitalizacion(){
    let datasms = { 'val': this.dataCitaMedicaHabitacion.historiasclinica.persona.nombre + ' ' + this.dataCitaMedicaHabitacion.historiasclinica.persona.apellido, 'msg': 'Cancelar la hospitalización del paciente' };
		this.notificationServices.msgConfir(datasms).then((datas)=>{
			if(datas){
        let citasTipoHospitalizacion:any=[];
        for (let i = 0; i < this.dataHospitalSaveOld.detallehostipalizaciones.length; i++) {
          const element = this.dataHospitalSaveOld.detallehostipalizaciones[i];
          if(element.citasmedica.cita==3){
            citasTipoHospitalizacion.push(element.citasmedica.id);
          }
        }
        let AdiccioneDataModel = {} as any;
        AdiccioneDataModel.id = this.dataHospitalSaveOld.id;
        AdiccioneDataModel.estado = 0;
        AdiccioneDataModel.medico = this.userdata.datos.persona.id;
        this.srvListaCitas.updateHospitalizacionesFin(AdiccioneDataModel).subscribe((dataupdate: any) => {
          if (dataupdate.estado) {
            this.notificationServices.setNotification(dataupdate);
            for (let j = 0; j < citasTipoHospitalizacion.length; j++) {
              const element = citasTipoHospitalizacion[j];
              this.srvListaCitas.updateCitasmedicasElement({id:element,estado:3}).subscribe((dataupdate: any) => {})
            }
            this.clickEvent.emit(dataupdate.datos);
          }
        })
      }
    })
  }

}
