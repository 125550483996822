import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VerInfoResumenesComponent } from '../../../appasclepio/citas-medicas-medicos/atencionesmedicas/modal/ver-info-resumenes/ver-info-resumenes.component';
import { localStorageServices } from '../../../appauth/auth/localStorageServices';
import * as moment from 'moment';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { ElementComponentService } from '../servicios/component.servicios';

@Component({
  selector: 'app-header-info-medico',
  templateUrl: './header-info-medico.component.html',
  styleUrls: ['./header-info-medico.component.scss']
})
export class HeaderInfoMedicoComponent implements OnInit {
  @Input() infoDataHeaderAtencion: any;
  @Output() clickEvent = new EventEmitter();
  configPdf: any = {
    size: 'xl',
    centered: true,
    resolve: { datasend: null }
  }
  tiempo: string="";
  dia:number=0;
  userdata: any;
  
  constructor(public localdata: localStorageServices,
    public dialog: NgbModal,
    public citasServicios:ElementComponentService,
    private notificationServices: NotificationServices,
    private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.userdata = this.localdata.getValueFromLocalStorage();
    if(this.infoDataHeaderAtencion.horainicio==null || this.infoDataHeaderAtencion.horainicio==undefined)
      this.infoDataHeaderAtencion.horainicio=this.infoDataHeaderAtencion.created;
    var a = moment(this.infoDataHeaderAtencion.horainicio);
    var b = moment();
    var m3 = b.diff(a, 'minutes');
    var numdays = Math.floor(m3 / 1440);
    var numhours = Math.floor((m3 % 1440) / 60);
    var numminutes = Math.floor((m3 % 1440) % 60);
    this.dia=numdays;
    this.tiempo=(numdays + " día(s) " + numhours + "h " + numminutes + " min.");
  }

  resumen() {
    this.ngxService.startLoader('princialLoder');
    let dialogRef = this.dialog.open(VerInfoResumenesComponent, this.configPdf);
    dialogRef.componentInstance.dataCitaMedicaInfo = this.infoDataHeaderAtencion.citasmedica;
    dialogRef.componentInstance.titulo = 'RESUMEN DE CITA MEDICA';
    this.ngxService.stopLoader('princialLoder');
    dialogRef.result.then(
      result => {

      },
      reason => {
        console.log(reason);
      }
    );
  }

  editar(){
    let data;
    if (this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.tipo == 1 || this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.tipo == 3) {
      data = { 'val': this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.nombre + ' ' + this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.apellido, 'msg': 'Editar atención médica' };
    }
    if (this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.tipo == 2) {
      data = { 'val': this.infoDataHeaderAtencion.citasmedica.historiasclinica.persona.razonsocial, 'msg': 'Editar atención médica' };
    }

    this.notificationServices.msgConfir(data).then((datas) => {
      if (datas) {
        let ResultadoexemeneDataModel = { id: this.infoDataHeaderAtencion.id, estado: 1 } as any;
        this.ngxService.startLoader('princialLoder');
        this.citasServicios.updateAtencionesmedicasElement(ResultadoexemeneDataModel).subscribe((data: any) => {
          if (data.estado) {
            this.notificationServices.setNotification(data);
            this.clickEvent.emit(data.datos);
            this.ngxService.stopLoader('princialLoder');
          } else {
            this.notificationServices.setNotification(data);
            this.ngxService.stopLoader('princialLoder');
          }
        });
      }
    });
  }

  

}
